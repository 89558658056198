.TypeSelect {
    width: 400px;
    display: flex;
}

@media screen and (max-width: 500px) {
    .TypeSelect {
        width: 100%;
    }
}

.TypeSelect button {
    flex: 1;
    border: 2px solid #ccc;
    border-right: 0;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 10px;
    color: #999;
    outline: 0;
    transition: all .3s;
    cursor: pointer;
}

.TypeSelect button:first-child {
    border-radius: 5px 0 0 5px;
}

.TypeSelect button:last-child {
    border-right: 2px solid #ccc;
    border-radius: 0 5px 5px 0;
}

.TypeSelect button.active {
    background: #fff;
    color: #D9534F;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .12);
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0 5%;
  background: #eee;
  font-family: "Roboto", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::selection {
  background: #d9534f;
  color: #fff;
}

.Content {
  display: flex;
  flex-direction: row;
  text-align: -webkit-center;
}

@media (max-width: 1000px) {
  .Content {
    flex-direction: column;
  }
}

.Pomodoro {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 600px;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0;
}

.TaskPainel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 600px;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0;
}

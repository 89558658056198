.ToggleSound {
    position: absolute;
    width: 27px;
    height: 24px;
    right: 25px;
    top: 25px;
    border: 0;
    background: transparent;
    font-size: 24px;
    color: #666;
    opacity: .5;
    transition: all .3s;
    outline: 0;
    cursor: pointer;
    padding: 0;
    text-align: left;
}

.ToggleSound.active {
    opacity: 1;
}
